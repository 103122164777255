import TableHead from "./TableHead";
import TableBody from "./TableBody";

export default function Table({rows}) {
	return (
		<div className={"table-responsive"}>
			<table className={"table"}>
				<caption className={"caption-top"}>Rows</caption>
				<TableHead />
				<TableBody rows={rows} />
			</table>
		</div>
	);
};
