export default function ShowScreenshot({screenshot, id}) {
	return (
		<>
			<button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target={`#screenshot${id}`}>Show screenshot</button>

			<div className="modal fade" id={`screenshot${id}`} tabIndex="-1" aria-labelledby={`screenshot${id}Label`} aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={`screenshot${id}Label`}>Screenshot for chosen xPath</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<img className={"w-100"} src={`${process.env.REACT_APP_endPoint}/files/${screenshot}`} />
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
