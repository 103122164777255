import {forwardRef} from "react";

export default forwardRef(({redirectedUrl, setRedirectedUrl}, ref) => {
	return (
		<div className="mb-3">
			<label htmlFor="htmlFile" className="form-label">HTML file:</label>
			<input className="form-control" type="file" id="htmlFile" ref={ref} accept={".html"}/>
		</div>
	);
});
