import ShowScreenshot from "./ShowScreenshot";
import Website from "../Job/Website";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";

export default function TableRow({row, index}) {
	const [isWebsiteOpen, setIsWebsiteOpen] = useState(false);
	const collapse = useRef();

	useEffect(() => {
		const collapseElement = collapse.current;

		collapseElement.addEventListener('show.bs.collapse', shownListener);
		collapseElement.addEventListener('hidden.bs.collapse', hiddenListener);

		function shownListener() {
			setIsWebsiteOpen(true);
		}
		function hiddenListener() {
			setIsWebsiteOpen(false);
		}

		return () => {
			collapseElement.removeEventListener('show.bs.collapse', shownListener);
			collapseElement.removeEventListener('hidden.bs.collapse', hiddenListener);
		};
	}, []);

	let bgClass = "";
	if (index % 2 === 0) {
		bgClass = "table-light";
	}

	return (
		<>
			<tr className={bgClass}>
				<td className={"align-middle"}><Link to={`/job/${row.id}`}>{row.domain}</Link></td>
				{row.xPath ?
					<>
						<td className={'w-100'}>
							<div className={"d-flex justify-content-between flex-nowrap text-nowrap gap-3 align-items-center"}>
								<a href={row.redirectedUrl} target={"_blank"}>{row.redirectedUrl}</a>
								<button className="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${row.id}`}
										aria-expanded="false" aria-controls={`collapse${row.id}`}>{isWebsiteOpen ? 'Hide': 'Show'} website</button>
							</div>
						</td>
						<td>
							<div className={"d-flex justify-content-between flex-nowrap text-nowrap gap-3 align-items-center"}>
								{row.xPath}
								{row.screenshot && <ShowScreenshot screenshot={row.screenshot} id={row.xPathId}/>}
							</div>
						</td>
					</>
					:
					<td colSpan={3}>Skipped</td>
				}
			</tr>
			<tr ref={collapse} className="collapse" id={`collapse${row.id}`}>
				<td colSpan="3">
					<div className="card card-body" style={{height: "750px"}}>
						{row.redirectedUrl && <Website redirectedUrl={row.redirectedUrl} domain={row.domain} />}
					</div>
				</td>
			</tr>
		</>
	);
};
