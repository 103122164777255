import authenticationContext from "./authenticationContext";
import {useContext, useEffect, useState} from "react";
import notificationContext from "../Notifications/notificationContext";
import Spinner from "../../components/Spinner";

export default function AuthenticationProvider ({children}) {
	const [freelancer, setFreelancer] = useState(null);
	const [job, setJob] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const {addToast} = useContext(notificationContext);

	useEffect(() => {
		const abortController = new AbortController();
		let isMounted = true;

		(async () => {
			setError(false);
			setLoading(true);

			try {
				await fetchJob();
				await fetchFreelancer();
			}
			catch (e) {
				console.error(e);
				isMounted && setFreelancer(null);
				isMounted && setJob(null);
				if (e.name !== "AbortError") {
					addToast({
						message:  e.name,
						description: e.message
					});
				}
				isMounted && setError(true);
			}
			finally {
				isMounted && setLoading(false);
			}
		})();

		async function fetchJob() {
			const response = await fetch(process.env.REACT_APP_endPoint + '/meta/job', {
				signal: abortController.signal,
				credentials: "include"
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Fetch job error",
							description: error
						});
					}
				}
				else {
					setJob(json.job);
				}
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					const error = new Error(json.errors[0]);
					error.name = "Authentication Error";
					throw error;
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		async function fetchFreelancer() {
			const response = await fetch(process.env.REACT_APP_endPoint + '/meta/freelancer', {
				signal: abortController.signal,
				credentials: "include"
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Fetch freelancer error",
							description: error
						});
					}
				}
				else {
					setFreelancer(json.freelancer);
				}
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					const error = new Error(json.errors[0]);
					error.name = "Authentication Error";
					throw error;
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}

		return () => {
			abortController && abortController.abort();
			isMounted = false;
		}
	}, []);

	if (error) {
		return (
			<h1>Please login at <a href="https://freelancer.datamundi.space">the freelancer portal</a>.</h1>
		);
	}
	else {
		return (
			<authenticationContext.Provider value={{
				freelancer,
				job,
				setError
			}}>
				{job && freelancer ? children:null}
				{loading && <Spinner />}
			</authenticationContext.Provider>
		);
	}
};
