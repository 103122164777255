import {useContext, useEffect, useState} from "react";
import Search from "./Search";
import Table from "./Table";
import notificationContext from "../../context/Notifications/notificationContext";
import Spinner from "../Spinner";
import Pagination from "./Pagination";

export default function Review() {
	const [search, setSearch] = useState('');
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [quantity, setQuantity] = useState(10);

	const {addToast} = useContext(notificationContext);

	useEffect(() => {
		const abortController = new AbortController();
		let isMounted = true;

		(async () => {
			setLoading(true);

			try {
				const urlParams = new URLSearchParams();
				urlParams.append('search', search);
				urlParams.append('quantity', quantity);
				urlParams.append('page', page);

				const response = await fetch(process.env.REACT_APP_endPoint + '/domains?' + urlParams.toString(), {
					signal: abortController.signal,
					credentials: "include"
				});
				if (response.status >= 200 && response.status < 300) {
					const json = await response.json();
					if (json.errors.length > 0) {
						for (let error of json.errors) {
							addToast({
								message: "Fetch rows error",
								description: error
							});
						}
					}
					else {
						isMounted && setRows(json.domains);
					}
				}
				else {
					addToast({
						message: response.status,
						description: response.statusText
					});
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					addToast({
						message: "Fetch error",
						description: e.message
					});
				}
			}
			isMounted && setLoading(false);
		})();
		return () => {
			abortController && abortController.abort();
			isMounted = false;
		};
	}, [search, page, quantity]);

	useEffect(() => {
		if (page < 0 || page > maxPage) {
			setPage(0);
		}
	}, [maxPage]);

	useEffect(() => {
		const abortController = new AbortController();
		let isMounted = true;

		(async () => {
			try {
				const urlParams = new URLSearchParams();
				urlParams.append('search', search);
				urlParams.append('quantity', quantity);

				const response = await fetch(process.env.REACT_APP_endPoint + '/domains/maxPage?' + urlParams.toString(), {
					signal: abortController.signal,
					credentials: "include"
				});
				if (response.status >= 200 && response.status < 300) {
					const json = await response.json();
					if (json.errors.length > 0) {
						for (let error of json.errors) {
							addToast({
								message: "Fetch maxpage error",
								description: error
							});
						}
					}
					else {
						isMounted && setMaxPage(json.maxPage);
					}
				}
				else {
					addToast({
						message: response.status,
						description: response.statusText
					});
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					addToast({
						message: "Fetch error",
						description: e.message
					});
				}
			}
		})();
		return () => {
			abortController && abortController.abort();
			isMounted = false;
		};
	}, [search, quantity]);


	return (
		<div className="m-0 flex-fill my-3 p-3 bg-white border shadow-sm ">
			<Search search={search} setSearch={setSearch}/>
			<hr />
			<Pagination page={page} setPage={setPage} maxPage={maxPage} quantity={quantity} setQuantity={setQuantity}/>
			{loading ? <div className={"my-3 p-3"}><Spinner /></div>: <Table rows={rows} />}
			<Pagination page={page} setPage={setPage} maxPage={maxPage} quantity={quantity} setQuantity={setQuantity}/>
		</div>
	);
};
