import React, {useContext} from "react";
import Logout from "./Logout";
// import {Link} from "react-router-dom";
import authenticationContext from "../context/Authentication/authenticationContext";
import {Link, useLocation} from "react-router-dom";

function Navbar() {
    const {job} = useContext(authenticationContext);
    const {pathname} = useLocation();

    return (
        <div className={"bg-white shadow-sm border p-3 d-flex justify-content-between"}>
            <Link to={pathname==="/review" ? '/job': '/review'} className={"btn btn-primary"}>{pathname === "/review" ? 'Back' : 'Review'}</Link>
            <div className={"d-flex align-items-center"}>
                <h4 className={"m-0"}>{job.name}</h4>
            </div>
            <Logout />
        </div>
    );
}

export default Navbar;
