import {useContext} from "react";
import Toast from "./Toast";
import notificationContext from "../../context/Notifications/notificationContext";

function Notifications() {
	const {toasts} = useContext(notificationContext);

	return (
		<div className={"toast-container position-fixed"} style={{zIndex: "100000", right: 5, top: 4}}>
			{toasts.map(value => {
				return <Toast message={value.message} description={value.description} key={value.id} id={value.id} />;
			})}
		</div>
	);
}

export default Notifications;
