import {useContext, useEffect, useRef, useState} from "react";
import notificationContext from "../Notifications/notificationContext";
import progressContext from "./progressContext";

export default function ProgressProvider({children}) {
	const [progress, setProgress] = useState({});
	const {addToast} = useContext(notificationContext);

	const abortController = useRef();

	useEffect(() => {
		(async () => {
			await fetchProgress();
		})();

		return () => {
			abortController.current && abortController.current.abort();
		}
	}, []);

	async function fetchProgress() {
		abortController.current && abortController.current.abort();
		abortController.current = new AbortController();

		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/meta/progress', {
				signal: abortController.current.signal,
				credentials: "include"
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Fetch progress error",
							description: error
						});
					}
				}
				else {
					setProgress(json.progress);
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message: "Fetch error",
					description: e.message
				});
			}
		}
	}

	return (
		<progressContext.Provider value={{
			progress,
			fetchProgress
		}}>
			{children}
		</progressContext.Provider>
	);
};
