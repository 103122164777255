import React, {useContext} from "react";
import authenticationContext from "../context/Authentication/authenticationContext";

function Logout() {
    const {setJob, setFreelancer, setError} = useContext(authenticationContext);

    async function onSubmit(evt) {
        evt.preventDefault();

        try {
            await fetch(process.env.REACT_APP_endPoint + "/logout", {
                method: "POST",
                credentials: "include"
            });
            setError(true);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <button type={"submit"} className={"btn btn-danger"}>Logout</button>
        </form>
    );
}

export default Logout;
