import Footer from "./components/Footer";
import Info from "./components/Info";
import ProgressProvider from "./context/Progress/ProgressProvider";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Routes,  Navigate, Route} from "react-router-dom";
import Job from "./components/Job";
import Review from "./components/Review";

function App() {
	return (
		<ProgressProvider>
			<div className="vh-100 p-3 d-flex flex-column">
				<Router>
					<Navbar />
					<Routes>
						<Route path={'/job/:domainId'} element={<Job />}/>
						<Route path={'/job'} element={<Job />}/>
						<Route path={'/review'} element={<Review />}/>
						<Route path="*" element={<Navigate to="/job" replace />} />
					</Routes>
				</Router>
				<Info />
			</div>
			<Footer />
		</ProgressProvider>
	);
}

export default App;
