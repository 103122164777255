import {XPathsProvider} from "../../context/xPaths";
import Column from "../Column";
import Website from "./Website";
import Data from "./Data";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import authenticationContext from "../../context/Authentication/authenticationContext";
import notificationContext from "../../context/Notifications/notificationContext";
import progressContext from "../../context/Progress/progressContext";
import RedirectedUrl from "./Data/RedirectedUrl";
import Spinner from "../Spinner";
import Html from "./Data/Html";

function Job() {
	const {domainId:domainIdParam} = useParams();
	const [domainId, setDomainId] = useState(domainIdParam || null)
	const [domain, setDomain] = useState({});
	const [xPathId, setXPathId] = useState(null);
	const [redirectedUrl, setRedirectedUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [xPathAvailable, setXPathAvailable] = useState(false);

	const htmlFile = useRef();

	const abortController = useRef();
	const navigate = useNavigate();

	const {setError} = useContext(authenticationContext);
	const {addToast} = useContext(notificationContext);
	const {fetchProgress} = useContext(progressContext);

	useEffect(() => {
		abortController.current && abortController.current.abort();
		abortController.current = new AbortController();
		let isMounted = true;
		setXPathAvailable(false);

		(async () => {
			setLoading(true);

			if (!domainId) {
				await getNext();
			}
			else {
				await getDomain();
			}
			isMounted && setLoading(false);
		})();

		return () => {
			abortController.current && abortController.current.abort();
			isMounted = false;
		}
	}, [domainId]);

	async function getNext() {
		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/domains/next', {
				credentials: "include",
				signal: abortController.current.signal
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (const error of json.errors) {
						addToast({
							message: "Fetch next row error",
							description: error
						});
					}
				}

				if (json.id) {
					setDomainId(json.id);
				}
				else {
					navigate('/review');
				}
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					addToast({
						message: "Authentication Error",
						description: json.errors[0]
					});
					setError(true);
				}
			}
			else if (response.status === 500) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (const error of json.errors) {
						addToast({
							message: "Fetch next row error",
							description: error
						});
					}
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message:  e.name,
					description: e.message
				});
			}
		}
	}

	async function getDomain() {
		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/domains/' + domainId, {
				credentials: "include",
				signal: abortController.current.signal
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Fetch row error",
							description: error
						});
					}
				}
				else {
					if (json.domain.html) {
						setXPathAvailable(true);
					}
					setDomain(json.domain);
					if (json.domain.xPathId) {
						setXPathId(json.domain.xPathId);
					}
					if (json.domain.redirectedUrl) {
						setRedirectedUrl(json.domain.redirectedUrl);
					}
					else {
						setRedirectedUrl("");
					}
				}
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					addToast({
						message: "Authentication Error",
						description: json.errors[0]
					});
					setError(true);
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message:  e.name,
					description: e.message
				});
			}
		}
	}

	async function submit() {
		abortController.current && abortController.current.abort();
		abortController.current = new AbortController();

		try {
			if (!redirectedUrl) {
				addToast({
					message: "Failed to submit",
					description: "Please enter a redirection URL."
				});
			}
			else if (!domain.html && htmlFile.current.files.length !== 1) {
				addToast({
					message: "Failed to submit",
					description: "Please upload an html file."
				});
			}
			else {
				const response = await fetch(process.env.REACT_APP_endPoint + '/domains/' + domainId + "/xPath", {
					credentials: "include",
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						xPathId
					}),
					signal: abortController.current.signal
				});
				if (response.status >= 200 && response.status < 300) {
					const json = await response.json();
					if (json.errors.length > 0) {
						for (let error of json.errors) {
							addToast({
								message: "Failed to submit",
								description: error
							});
						}
					}
					else {
						if (domainIdParam) {
							console.log("Redirect?");
							navigate('/review');
						}
						else {
							setXPathId(null);
							await getNext();
							await fetchProgress();
						}
					}
				}
				else if (response.status === 403) {
					const json = await response.json();
					if (json.errors.length > 0) {
						addToast({
							message: "Authentication Error",
							description: json.errors[0]
						});
						setError(true);
					}
				}
				else {
					addToast({
						message: response.status,
						description: response.statusText
					});
				}
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message:  e.name,
					description: e.message
				});
			}
		}
	}

	async function submitBasicInfo(evt) {
		evt.preventDefault();
		abortController.current && abortController.current.abort();
		abortController.current = new AbortController();
		setXPathAvailable(false);

		try {
			if (!redirectedUrl) {
				addToast({
					message: "Failed to submit",
					description: "Please enter a redirection URL."
				});
			}
			else if (!domain.html && htmlFile.current.files.length !== 1) {
				addToast({
					message: "Failed to submit",
					description: "Please upload an html file."
				});
			}
			else {
				let html = null;
				if (htmlFile.current) {
					html = await htmlFile.current.files[0].text();
				}

				const response = await fetch(process.env.REACT_APP_endPoint + '/domains/' + domainId, {
					credentials: "include",
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						html,
						redirectedUrl
					}),
					signal: abortController.current.signal
				});
				if (response.status >= 200 && response.status < 300) {
					const json = await response.json();
					if (json.errors.length > 0) {
						for (let error of json.errors) {
							addToast({
								message: "Failed to submit",
								description: error
							});
						}
					}
					else {
						setXPathAvailable(true);
					}
				}
				else if (response.status === 403) {
					const json = await response.json();
					if (json.errors.length > 0) {
						addToast({
							message: "Authentication Error",
							description: json.errors[0]
						});
						setError(true);
					}
				}
				else {
					addToast({
						message: response.status,
						description: response.statusText
					});
				}
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message:  e.name,
					description: e.message
				});
			}
		}
	}

	async function skip() {
		abortController.current && abortController.current.abort();
		abortController.current = new AbortController();

		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/domains/' + domainId + '/skip', {
				credentials: "include",
				method: "POST",
				signal: abortController.current.signal
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Failed to skip",
							description: error
						});
					}
				}
				else {
					if (domainIdParam) {
						navigate('/review');
					}
					else {
						setXPathId(null);
						setRedirectedUrl("");
						await getNext();
						await fetchProgress();
					}
				}
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					addToast({
						message: "Authentication Error",
						description: json.errors[0]
					});
					setError(true);
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		catch (e) {
			console.error(e);
			if (e.name !== "AbortError") {
				addToast({
					message:  e.name,
					description: e.message
				});
			}
		}
	}

	return (
		<div className="row flex-nowrap overflow-hidden m-0 gap-3 flex-fill my-3">
			<XPathsProvider domainId={domainId}>
				<Column>
					{loading ? <Spinner />:
						<form className={"d-flex flex-column flex-grow-1"} onSubmit={submitBasicInfo}>
							<RedirectedUrl redirectedUrl={redirectedUrl} setRedirectedUrl={setRedirectedUrl}/>
							{!domain.html && <Html ref={htmlFile}/>}
							{!xPathAvailable && <div className={"mb-3"}>
								<button type={"submit"} className={"btn btn-success w-100"}>Save</button>
							</div>}
							<Website redirectedUrl={redirectedUrl} domain={domain.domain}/>
						</form>
					}
				</Column>
				<Column>
					{loading ? <Spinner />:	xPathAvailable && <Data submit={submit} skip={skip} xPathId={xPathId} setXPathId={setXPathId}/>}
				</Column>
			</XPathsProvider>
		</div>
	);
}

export default Job;
