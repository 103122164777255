import notificationContext from "./notificationContext";
import {useState} from "react";
import {v4 as uuidv4} from "uuid";
import Notifications from "../../components/Notifications";

export default function NotificationProvider ({children}) {
	const [toasts, setToasts] = useState([]);

	function addToast({message, description}) {
		setToasts((toasts) => {
			return [...toasts, {message,  description, id: uuidv4()}];
		});
	}
	function removeToast(id) {
		setToasts((toasts) => {
			return toasts.filter(value => value.id !== id)
		});
	}

	return (
		<notificationContext.Provider value={{
			toasts,
			addToast,
			removeToast
		}}>
			<Notifications />
			{children}
		</notificationContext.Provider>
	);
};
