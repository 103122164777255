import {createContext, useContext, useEffect, useState} from "react";
import notificationContext from "./Notifications/notificationContext";
import authenticationContext from "./Authentication/authenticationContext";

export const xPathsContext = createContext({
	xPaths: [],
	setXPaths: [],
	loading: false,
	domainId: null
});

export function XPathsProvider({children, domainId}) {
	const [xPaths, setXPaths] = useState([]);
	const [loading, setLoading] = useState(false);
	const {addToast} = useContext(notificationContext);
	const {setError} = useContext(authenticationContext);

	useEffect(() => {
		const abortController = new AbortController();

		setXPaths([]);
		(async () => {
			if (domainId) {
				setLoading(true);

				try {
					const urlParams = new URLSearchParams();
					urlParams.append('domainId', domainId);
					const response = await fetch(process.env.REACT_APP_endPoint + '/xPaths?' + urlParams.toString(), {
						signal: abortController.signal,
						credentials: "include"
					});
					if (response.status >= 200 && response.status < 300) {
						const json = await response.json();
						if (json.errors.length > 0) {
							for (let error of json.errors) {
								addToast({
									message: "Fetch xPaths error",
									description: error
								});
							}
						}

						setXPaths(json.xPaths);
					}
					else if (response.status === 403) {
						const json = await response.json();
						if (json.errors.length > 0) {
							addToast({
								message: "Authentication Error",
								description: json.errors[0]
							});
							setError(true);
						}
					}
					else if (response.status === 500) {
						const json = await response.json();
						if (json.errors.length > 0) {
							for (let error of json.errors) {
								addToast({
									message: "Fetch xPaths error",
									description: error
								});
							}
						}
					}
					else {
						addToast({
							message: response.status,
							description: response.statusText
						});
					}
				}
				catch (e) {
					console.error(e);
					if (e.name !== "AbortError") {
						addToast({
							message:  e.name,
							description: e.message
						});
					}
				}
				finally {
					setLoading(false);
				}
			}
		})();

		return () => {
			abortController.abort();
		}
	}, [domainId]);

	return (
		<xPathsContext.Provider value={{
			xPaths,
			setXPaths,
			loading,
			domainId
		}}>
			{children}
		</xPathsContext.Provider>
	);
}
