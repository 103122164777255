
export default function RedirectedUrl({redirectedUrl, setRedirectedUrl}) {
	return (
		<div className={"mb-3"}>
			<label htmlFor="redirectedUrl" className={"form-label"}>Redirected URL:</label>
			<input type={"url"} className={"form-control"} id={"redirectedUrl"} required={true} value={redirectedUrl} onChange={(evt) => {
				setRedirectedUrl(evt.target.value);
			}} />
		</div>
	);
};
