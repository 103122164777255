import React from "react";

export default function Screenshot({img, id, xPath, selectedScreenshot, setSelectedScreenshot}) {
	let {onMouseEnter, onMouseLeave, style} = useHover({
		backgroundColor: "#dee2e6",
		padding: "10px",
		margin: "0",
		cursor: "pointer"
	}, {
		backgroundColor: "rgb(248, 249, 250)",
		padding: "5px",
		margin: "5px"
	});

	if (selectedScreenshot === id) {
		style = {...style, backgroundColor: "green"};
	}

	return (
		<div className={"border"} onClick={() => {
			setSelectedScreenshot(id);
		}}  onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style}>
			<h5>{xPath}</h5>
			{img && <img src={process.env.REACT_APP_endPoint + "/files/" + img}/>}
		</div>
	);
}

function useHover(styleOnHover, styleOnNotHover = {})
{
	const [style, setStyle] = React.useState(styleOnNotHover);

	const onMouseEnter = () => setStyle(styleOnHover)
	const onMouseLeave = () => setStyle(styleOnNotHover)

	return {style, onMouseEnter, onMouseLeave}
}
