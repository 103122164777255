import React, {useContext} from "react";
import authenticationContext from "../context/Authentication/authenticationContext";
import progressContext from "../context/Progress/progressContext";

function Info() {
	const {job, freelancer} = useContext(authenticationContext);
	const {progress} = useContext(progressContext);

	return (
		<div className={"bg-white shadow-sm border p-3 text-center"}>
			<span className={""}>Evaluator: {freelancer.firstName} {freelancer.lastName} -- Job running from {job.publishDate} till {job.dueDate} -- You have done {progress.done}/{progress.total} ({progress.percentage}%).</span>
		</div>
	);
}

export default Info;
