import Screenshot from "./Screenshot";
import {xPathsContext} from "../../../context/xPaths";
import {useContext, useState} from "react";
import Spinner from "../../Spinner";
import AddXPath from "./AddXPath";
import {useParams} from "react-router-dom";

export default function Data({xPathId, setXPathId, submit, skip}) {
	const {xPaths,loading} = useContext(xPathsContext);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [skipLoading, setSkipLoading] = useState(false);
	const {domainId:domainIdParam} = useParams();
	const [xPath, setXPath] = useState("");

	async function onSubmit() {
		setSubmitLoading(true);

		try {
			await submit();
			setXPath('');
		}
		catch (e) {}

		setSubmitLoading(false);
	}
	async function onSkip() {
		setSkipLoading(true);

		try {
			await skip();
			setXPath('');
		}
		catch (e) {}

		setSkipLoading(false);
	}

	return (
		<>
			<AddXPath xPath={xPath} setXPath={setXPath}/>
			<hr />
			{loading ? <Spinner/> :
				<div className="d-flex flex-wrap gap-2 overflow-auto">
					{xPaths.map((value, index) => {
						return <Screenshot id={value.id} key={value.id} img={value.screenshot} xPath={value.xPath}
							   selectedScreenshot={xPathId} setSelectedScreenshot={setXPathId}/>
					})}
				</div>
			}
			<hr />
			<div className="d-flex  gap-2">
				<button type={"button"} className={"btn btn-success w-100"} onClick={onSubmit} disabled={!xPathId || submitLoading}>{submitLoading ? <Spinner /> : domainIdParam ? 'Save': 'Submit & Next'}</button>
				<button type={"button"} className={"btn btn-danger w-100"} onClick={onSkip} disabled={skipLoading}>{skipLoading ? <Spinner /> : 'Skip'}</button>
			</div>

		</>
	);
}
