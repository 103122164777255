import {useContext, useState} from "react";
import {xPathsContext} from "../../../context/xPaths";
import Error from "../../Error";
import Spinner from "../../Spinner";
import notificationContext from "../../../context/Notifications/notificationContext";
import authenticationContext from "../../../context/Authentication/authenticationContext";

export default function AddXPath({xPath, setXPath}) {
	const {setXPaths, domainId} = useContext(xPathsContext);
	const {addToast} = useContext(notificationContext);
	const {setError} = useContext(authenticationContext);
	const [loading, setLoading] = useState(false);

	async function onSubmit(evt) {
		evt.preventDefault();
		setLoading(true);

		try {
			const response = await fetch(process.env.REACT_APP_endPoint + "/xPaths", {
				credentials: "include",
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					xPath,
					domainId
				})
			});
			if (response.status >= 200 && response.status < 300) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Add xPath error",
							description: error
						});
					}
				}
				setXPaths(prevState => {
					return [...prevState, json.xPath];
				});
				setXPath('');
			}
			else if (response.status === 403) {
				const json = await response.json();
				if (json.errors.length > 0) {
					const error = new Error(json.errors[0]);
					error.name = "Authentication Error";
					throw error;
				}
			}
			else if (response.status === 500) {
				const json = await response.json();
				if (json.errors.length > 0) {
					for (let error of json.errors) {
						addToast({
							message: "Add xPath error",
							description: error
						});
					}
				}
			}
			else {
				addToast({
					message: response.status,
					description: response.statusText
				});
			}
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<div className={"mb-3"}>
				<label htmlFor="xPath" className={"form-label"}>Xpath to add:</label>
				<input type={"text"} id={"xPath"} className={"form-control"} value={xPath} onChange={(evt) => {
					setXPath(evt.target.value);
				}} required={true} />
			</div>

			<button type={"submit"} disabled={loading} className={"btn w-100 btn-success"}>{loading ? <Spinner/> : 'Add xPath to the list'}</button>
		</form>
	)
}
