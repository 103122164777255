import TableRow from "./TableRow";

export default function TableBody({rows}) {
	return (
		<tbody>
		{rows.map((value, index) => {
			return <TableRow row={value} index={index} key={value.id} />;
		})}
		</tbody>
	);
};
