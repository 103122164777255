function Footer() {
	return (
		<div className="p-3 align-items-center d-flex justify-content-center border mt-5">
			<span>Copyright @ 2022 Datamundi - <a
				href="https://freelancer.datamundi.space/pdf/Privacy policy-EN_DATAMUNDI_V2.pdf" target="_blank">Privacy Policy</a></span>
		</div>
	);
}

export default Footer;
