
export default function Pagination({page, setPage, maxPage, quantity, setQuantity}) {
	const hasPreviousPage = page > 0;
	const hasNextPage = page < maxPage;

	return (
		<div className={"d-flex justify-content-between align-items-center"}>
			<div className={"d-flex"}>
				<div>
					<ul className={"pagination m-0"}>
						<li className={"page-item" + (!hasPreviousPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={!hasPreviousPage} onClick={(evt) => {
								setPage(0);
							}}>&lt;&lt;</a>
						</li>
						<li className={"page-item" + (!hasPreviousPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={!hasPreviousPage} onClick={(evt) => {
								setPage(page => page - 1);
							}}>&lt;</a>
						</li>
					</ul>
				</div>
				<div className={"ms-2"}>
					<select className={"form-select"} value={quantity} onChange={(evt) => {
						setQuantity(evt.target.value);
					}}>
						<option value="" disabled={true}>Select a quantity</option>
						<option value="5">5</option>
						<option value="10">10</option>
						<option value="15">15</option>
						<option value="20">20</option>
						<option value="25">25</option>
					</select>
				</div>
			</div>
			<div>
				Page {page + 1}/{maxPage + 1}
			</div>
			<div className={"d-flex"}>
				<div className={"me-2"}>
					<select className={"form-select"} value={quantity} onChange={(evt) => {
						setQuantity(evt.target.value);
					}}>
						<option value="" disabled={true}>Select a quantity</option>
						<option value="2">5</option>
						<option value="10">10</option>
						<option value="15">15</option>
						<option value="20">20</option>
						<option value="25">25</option>
					</select>
				</div>
				<div>
					<ul className={"pagination m-0"}>
						<li className={"page-item" + (!hasNextPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={!hasNextPage} onClick={(evt) => {
								setPage(page => page + 1);
							}}>&gt;</a>
						</li>
						<li className={"page-item" + (!hasNextPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={!hasNextPage} onClick={(evt) => {
								setPage(maxPage);
							}}>&gt;&gt;</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
