
export default function TableHead() {
	return (
		<thead className={"table-dark"}>
		<tr>
			<th>Domain</th>
			<th>Redirected url</th>
			<th>xPath</th>
		</tr>
		</thead>
	);
};
